<template>
  <div v-if="dialog">
    <v-dialog
      v-model="dialog"
      max-width="764px"
      @click:outside="closeModal"
      @keydown.esc="closeModal"
    >
      <v-card class="pa-10 pb-1 pa-md-12 pb-md-2" style="max-height: 490px">
        <v-card-title class="mt-2 pa-0 justify-center">
          <div
            class="font-weight-bold text-h3 text-md-h2"
            style="word-break: break-word"
          >
            Registro de proveedor
          </div>
          <v-btn
            icon
            :ripple="false"
            absolute
            right
            top
            class="btn-background-transparent hover-close-btn"
            @click="closeModal"
          >
            <v-icon large color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 black--text text-center">
          <div class="text-body-1 text-md-h4 my-8 mb-4 px-16">
            Únete a la red de proveedores Zaturna y comienza a organizar eventos
          </div>
          <v-img
            :src="require(`@/assets/icons/Solicitudes.png`)"
            contain
            height="68"
            class="icon mb-4"
          />
        </v-card-text>
        <v-card-actions class="justify-center d-flex flex-column">
          <Button
            text="Registrarme"
            aria-label="Ir al registro de proveedor"
            url="RegisterProvider"
          />
          <v-btn
            depressed
            text
            :ripple="false"
            class="mt-3 text-h4 font-weight-black text-decoration-underline btn-background-transparent"
            @click="closeModal"
          >
            Omitir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "ModalCompleteProfile",
  components: {
    Button,
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
