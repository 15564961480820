<template>
  <v-container>
    <v-row class="my-3 justify-center justify-sm-start">
      <v-col
        v-for="(card, i) in cards"
        :key="i"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="pa-6 pa-md-5 d-flex justify-center"
      >
        <div class="d-flex justify-center">
          <Card :card="card" :type="type" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Card from "@/components/Shared/Card.vue";

export default {
  name: "ListCards",
  components: {
    Card,
  },
  props: {
    cards: { type: Array, required: true },
    type: { type: String, required: true },
  },
};
</script>

<style></style>
